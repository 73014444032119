import { Routes, Route, useLocation } from "react-router-dom";
import "./App.css";
import GenerateKey from "./components/generationkey/generationkey";
import Sidebar from "./components/Sidbar";
import Key from "./components/generationkey/key";
import Login from "./components/login/Login";
import { useState } from "react";
import NavBar from "../src/components/navbar/NavBar";
import EnterprisesList from "./components/enterprises/List";
import GenerationkeyList from "./components/generationkey/List";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import UserManagement from "./components/usermanagement/UserManagement";

function App() {
  const location = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const isAuthRoute = location.pathname === "/";
  const isVideoPage = location.pathname === "/video";
  const isVideoPlayerPage = location.pathname === "/video-player";

  // const PrivateRoute = ({ element }) => {
  //   const isAuthenticated = localStorage.getItem("isAuthenticated") === "true";
  //   return isAuthenticated ? element : <Navigate to="/" />;
  // };

  return (
    <div>
      {!isAuthRoute && !isVideoPage && !isVideoPlayerPage && (
        <NavBar sidebarOpen={sidebarOpen} />
      )}
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Login />} />

        <Route
          path="/*"
          element={
            <div style={{ display: "flex" }}>
              <Sidebar
                setSidebarOpen={setSidebarOpen}
                sidebarOpen={sidebarOpen}
              />
              <div style={{ padding: "1rem", flex: 1, marginTop: "54px" }}>
                <Routes>
                  <Route path="/key" element={<Key />} />
                  <Route path="/add-generatekey" element={<GenerateKey />} />
                  <Route path="/enterprises" element={<EnterprisesList />} />
                  <Route path="/usermanagement" element={<UserManagement />} />
                  <Route path="/generatekeys" element={<GenerationkeyList />} />
                </Routes>
              </div>
            </div>
          }
        />
      </Routes>
      <ToastContainer />
    </div>
  );
}

export default App;
