import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Typography,
  TextField,
  Modal,
  Box,
  Switch,
  CircularProgress,
} from "@mui/material";
import keyicon from "../../assets/svg/SideBar/keyicon.svg";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import "./enterprises.css";
import { Addenterprise, Editenterprise } from "../../store/enterprise/action";
import { validateEnterpriseKeyApi } from "../../services/enterprise/enterprise";

const Enterprises = ({ open, OnClose, editdata }) => {
  const [formState, setFormState] = useState({
    EnterpriseName: "",
    EnterpriseDescription: "",
    IsActive: true,
    EnterpriseTypeID: "",
    EnterpriseEmail: "",
    EnterpriseID: "",
    EnterpriseToken: "",
    MacInterFaces:null
  });
  const [uniqueCode, setUniqueCode] = useState("");
  const [errors, setErrors] = useState({});
  // const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validatedKey, setValidatedKey] = useState(null);
  const dispatch = useDispatch();

  const validateForm = () => {
    const newErrors = {};
    if (!formState.EnterpriseName)
      newErrors.EnterpriseName = "Enterprise Name is required.";
    if (!formState.EnterpriseEmail)
      newErrors.EnterpriseEmail = "Email is required.";
    if (!formState.EnterpriseDescription)
      newErrors.EnterpriseDescription = "Description is required.";
    if (!formState.EnterpriseToken)
      newErrors.EnterpriseToken = "Token is required.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleClick = async () => {
    if (!validateForm()) return;
    setLoading(true);
    try {
      const res = await dispatch(Editenterprise(formState));
      if (res) {
        OnClose();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleGenerate = () => {
    if (!formState.EnterpriseName) {
      setUniqueCode("Enterprise Name is required to generate a code.");
      return;
    }
    const generatedCode = `${uuidv4()}`;
    setUniqueCode(generatedCode);
    setFormState((prev) => ({ ...prev, EnterpriseToken: generatedCode }));
  };

  const handleClose = () => {
    OnClose();
    setErrors({});
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setFormState((prev) => ({ ...prev, [name]: value }));
  };
  const onChangeKey = (e) => {
    setValidatedKey(e.target.value);
  };
  const handleValidate = async () => {
    if (validatedKey) {
      const res = await validateEnterpriseKeyApi({ EncryptedKey: validatedKey });
      if (res?.data?.data?.EnterpriseID) {
        const {
          OrganizationStructureName,
          OrganizationStructureDescription,
          OrganizationStructureEmail,
          OrganizationStructureToken,
          EnterpriseID,
          MacInterFaces
        } = res?.data?.data;
        setFormState({
          EnterpriseName: OrganizationStructureName,
          EnterpriseDescription: OrganizationStructureDescription,
          IsActive:true,
          EnterpriseEmail: OrganizationStructureEmail,
          EnterpriseID,
          EnterpriseToken: OrganizationStructureToken,
          MacInterFaces
        })
        if (OrganizationStructureToken) {
          setUniqueCode(OrganizationStructureToken)
        }
      }
    }
  }

  useEffect(() => {
    if (editdata) {
      setFormState(editdata);
      setUniqueCode(editdata?.EnterpriseToken);
    }
  }, [editdata]);

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            width: 600,
            bgcolor: "background.paper",
            borderRadius: 1,
            padding: 4,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            boxShadow: 24,
          }}
        >
          <Box display="flex" flexDirection="column" gap="24px">
            <Box display="flex" alignItems="center" gap="16px">
              <img src={keyicon} alt="logo" />
              <Box>
                <Typography variant="h6">
                  {editdata ? "Edit Enterprise" : "New Enterprise"}
                </Typography>
                <Typography variant="body2" style={{ color: "#64748B" }}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </Typography>
              </Box>
            </Box>
            <Divider />
            <Box>
              <Typography>Organization Generated Key</Typography>
              <Box display="flex" alignItems="center" gap="8px">
                <TextField
                  type="text"
                  value={validatedKey}
                  onChange={onChangeKey}
                  placeholder="Generated code will appear here..."
                  readOnly
                  fullWidth
                  size="small"
                />
                <Button
                  variant="contained"
                  onClick={handleValidate}
                  size="small"
                >
                  Validate
                </Button>
              </Box>
            </Box>
            <Divider />
            <Box>
              <Typography>Enterprise Name</Typography>
              <TextField
                type="text"
                placeholder="Enter enterprise name..."
                name="EnterpriseName"
                onChange={onChange}
                value={formState.EnterpriseName}
                error={!!errors.EnterpriseName}
                helperText={errors.EnterpriseName}
                fullWidth
                variant="outlined"
                size="small"
              />
            </Box>
            <Box>
              <Typography>Enterprise Description</Typography>
              <TextField
                multiline
                rows={4}
                name="EnterpriseDescription"
                placeholder="Enter enterprise description..."
                variant="outlined"
                fullWidth
                onChange={onChange}
                value={formState.EnterpriseDescription}
                error={!!errors.EnterpriseDescription}
                helperText={errors.EnterpriseDescription}
                size="small"
              />
            </Box>
            <Box>
              <Typography>Email</Typography>
              <TextField
                type="email"
                placeholder="Enter email..."
                name="EnterpriseEmail"
                onChange={onChange}
                value={formState.EnterpriseEmail}
                error={!!errors.EnterpriseEmail}
                helperText={errors.EnterpriseEmail}
                fullWidth
                variant="outlined"
                size="small"
              />
            </Box>
            <Box>
              <Typography>Unique Code</Typography>
              <Box display="flex" alignItems="center" gap="8px">
                <TextField
                  type="text"
                  value={uniqueCode}
                  placeholder="Generated code will appear here..."
                  readOnly
                  fullWidth
                  size="small"
                />
                <Button
                  variant="contained"
                  onClick={handleGenerate}
                  size="small"
                  disabled={!!uniqueCode}
                >
                  Generate Code
                </Button>
              </Box>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              gap="16px"
            >
              <Box display="flex" alignItems="center" gap="16px">
                <Switch
                  checked={formState.IsActive}
                  onChange={() =>
                    setFormState((prev) => ({
                      ...prev,
                      IsActive: !prev.IsActive,
                    }))
                  }
                />
                <div>
                  <Typography variant="b"> Status</Typography>
                  <Typography variant="body2">Change status</Typography>
                </div>
              </Box>
              <Box>
                <Typography
                  variant="body2"
                  color={formState.IsActive ? "#15803D" : "#B91C1C"}
                  sx={{
                    bgcolor: formState.IsActive ? "#F0FDF4" : "#FEF2F2",
                    padding: "4px 12px",
                    borderRadius: "16px",
                  }}
                >
                  {formState.IsActive ? "Active" : "Inactive"}
                </Typography>
              </Box>
            </Box>
            <Divider />
            <Box mt={3} display="flex" justifyContent="space-between">
              <Button
                variant="outlined"
                onClick={handleClose}
                style={{ marginRight: 8 }}
                size="small"
                fullWidth
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={handleClick}
                size="small"
                disabled={loading}
                fullWidth
              >
                {loading ? <CircularProgress size={24} /> : "Save"}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default Enterprises;
