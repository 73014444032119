import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Divider,
} from "@mui/material";
import { Search, Delete, Edit } from "@mui/icons-material";
import xicon from "../../assets/svg/Xicon.svg";
import Filterslines from "../../assets/svg/filter.svg";
// import { deleteRole, GetRoleList } from "../../../store/enterprise/action";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from "../../assets/svg/Addicon.svg";
import { useNavigate } from "react-router-dom";
import { getGenerationkeys } from "../../store/keygenreration/action";
// Dummy data

const getStatusStyle = (status) => ({
  color: status ? "#027A48" : "#B42318",
  backgroundColor: status ? "#ECFDF3" : "#FEF3F2",
  padding: "5px 10px",
  borderRadius: "15px",
  display: "inline-block",
});

const GenerationkeyList = () => {
  const disptach = useDispatch();
  const { generationkeysList } = useSelector((state) => state.keygenreration);
  // const [open, setOpen] = useState(false);
  // const [editdata, setEditdata] = useState(null);
  // const [Alert, setAlert] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    disptach(getGenerationkeys());
  }, []);

  const onConfirm = () => {
    const payload = {
      // RoleID: editdata?.RoleID,
    };
    // disptach(deleteRole(payload));
    // setAlert(false);
  };
const formattedDate = (dateString)=>{
  const date = new Date(dateString); // Specify the date
return date.toLocaleDateString('en-GB', {
  day: '2-digit', // 2-digit day (31)
  month: 'short', // Short month name (Oct)
  year: 'numeric' // Full year (2024)
});
}
  return (
    <>
      <Box
        sx={{
          margin: "25px",
          borderRadius: "8px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Typography variant="h6" style={{ fontWeight: "500" }}>
            Generation keys
          </Typography>
          <Typography variant="body2" color="textSecondary">
            List of all the keys
          </Typography>
        </Box>

        <Button
          sx={{
            height: "40px",
          }}
          variant="contained"
          size="small"
          endIcon={<img src={AddIcon} />}
          onClick={() => {
            navigate("/add-generatekey");
          }}
        >
          Add
        </Button>
      </Box>
      <Box
        sx={{
          padding: "20px",
          backgroundColor: "#fff",
          margin: "25px",
          borderRadius: "8px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {/* Search Box with Icons */}
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Button
              variant="outlined"
              style={{
                backgroundColor: "#F2F4FE",
                borderRadius: "8px",
                textTransform: "none",
                color: "#3B82F6",

                gap: "8px", // Add space between text and image
              }}
            >
              All Time
              <span>
                <img
                  src={xicon}
                  alt="icon"
                  style={{
                    width: "16px",
                    height: "16px",
                    display: "flex",
                    alignItems: "center",
                  }}
                />
              </span>
            </Button>

            <Button
              variant="outlined"
              style={{
                borderColor: "#D0D5DD",
                color: "#344054",
                borderRadius: "8px",
                gap: "8px",
              }}
            >
              <span>
                <img
                  src={Filterslines}
                  alt=""
                  style={{
                    width: "16px",
                    height: "16px",
                    display: "flex",
                    alignItems: "center",
                  }}
                />
              </span>{" "}
              More filters
            </Button>
          </Box>
          <Box>
            {/* <TextField
              placeholder="Search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              sx={{
                width: "250px",
                height: "44px", // Set the height to 44px
                "& .MuiOutlinedInput-root": {
                  borderRadius: "8px", // Border radius
                  borderColor: "#D0D5DD", // Set border color
                  height: "44px", // Ensure the input height is 44px
                  "& fieldset": {
                    borderColor: "#D0D5DD", // Border color for outline
                  },
                  "&:hover fieldset": {
                    borderColor: "#D0D5DD", // Border color on hover
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#D0D5DD", // Border color when focused
                  },
                },
              }}
            /> */}
          </Box>
        </Box>
        {/* <Divider sx={{ marginTop: 2 }} /> */}
        {/* Table */}
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Enterprise Name</TableCell>
                <TableCell>License Name</TableCell>
                <TableCell>Admin</TableCell>
                <TableCell>End Users</TableCell>
                <TableCell>Process Owner</TableCell>
                <TableCell>Valid From</TableCell>
                <TableCell>Valid To</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {generationkeysList?.data?.map((row) => (
                <TableRow
                  key={row.LicenseTypeID}
                  onClick={() =>
                    navigate(`/key`, {
                      state: { ...row },
                    })
                  }
                >
                  <TableCell>{row?.EnterpriseName}</TableCell>
                  <TableCell>{row?.LicenseName}</TableCell>
                  <TableCell>{row?.NumberOfAdminUsers}</TableCell>
                  <TableCell sx={row?.PerpetualEndUser?{fontSize:25}:""}>{row?.PerpetualEndUser?'∞':row?.NumberOfEndUsers}</TableCell>
                  <TableCell sx={row?.PerpetualProcessOwner?{fontSize:25}:""}>{row?.PerpetualProcessOwner?'∞':row?.NumberOfProcessOwnerUsers}</TableCell>
                  <TableCell>{formattedDate(row?.ValidityFrom)}</TableCell>
                  <TableCell>{formattedDate(row?.ValidityTo)}</TableCell>
                  {/* <TableCell>
                    <IconButton
                      onClick={() => {
                        setOpen(!open);
                        setEditdata(row);
                      }}
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        setAlert(!Alert);
                        setEditdata(row);
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {/* Pagination */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 2,
          }}
        >
          <div>
            <Button
              variant="outlined"
              sx={{
                borderColor: "#D0D5DD",
                color: "#344054",
                textTransform: "none",
                marginRight: "12px",
                borderRadius: "8px",
              }}
            >
              Previous
            </Button>
            <Button
              variant="outlined"
              sx={{
                borderColor: "#D0D5DD",
                color: "#344054",
                textTransform: "none",
                borderRadius: "8px",
              }}
            >
              Next
            </Button>
          </div>
          <div>
            <Typography sx={{ color: "#344054" }}>Page 1 of 10</Typography>
          </div>
        </Box>
      </Box>
    </>
  );
};

export default GenerationkeyList;
