import { attempt } from "lodash";

export const BASE_URL_AUTH = "https://apiplutoauth.projectzerozilla.com/";
export const BASE_URL = "https://apipluto.projectzerozilla.com/"; // base URl
// export const BASE_URL = "http://localhost:4321/"; // base URl
export const CLIENT_ID = "addc0605-1e9a-4abc-bd3e-3a02ecfb4364";
export const REDIRECT_URI = "http://localhost:5173/dashboard";
export const CLIENT_SECRET =
  "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCXzZerpx9qdaelwt1U7NCpWXQKkm1OW4ohDF/7g01xDtYf8Nox9wzhhVQrFD+G4eaJoWxIhJYQTgT4ijMlpjXs07McwktcMX49h6Eoo6ZddOMl380UpivkaO+h80miG4JCFAM0G0pUoeNT8h6L9zHqr/yEoBRd3RAsqxeCKwKrswIDAQAB";
export const STATE = "myState";
export const GRAND_TYPE = "authorization_code";
export const RESPONSE_TYPE = "code";

const version = "v1"; // To control the API version
export const ENDPOINT_URL = {
  auth: {
    login: `api/v1/auth/login`,
    // access_token: `${version}/iCR2Y/UTNhC5mFGtIYuod`,
    logout: `${version}/iCR2Y/X918ByG1hVUKatj`,
  },
  keygenreration: {
    create: `${version}/P7Y4Y/awdH6VteQbIX7ym`,
    getGenerationkeys: `${version}/P7Y4Y/nKvx02eZCZ5hWD5`,
    sent: `${version}/P7Y4Y/LIAJvH1UCNVgS1M`,
  },
  elements: {
    left_side_bar: `${version}/rXos1/xDO7as9PXrvJ6Jz`,
  },
  global_search: {
    main_search: `${version}/rXos1/J4dG8xLpM2aR9eK`,
  },
  enterprise: {
    create: `${version}/P7Y4Y/rp6RaU3pdk6O63i`,
    getinterprise: `${version}/J2nve/VcMLIlIlQdVcw8G`,
    delete: `${version}/J2nve/J1HZlRXTzDEdUko`,
    edit: `${version}/J2nve/CaQzTD6mC8VzHg1`,
    fetchkeysvalue: `${version}/J2nve/xLoZneo6fA93l8x`,
    fetchpreviouslicensedetails: `${version}/J2nve/wLnMoL8Egm3v8hK`,
    orgadminlist: `${version}/J2nve/UB1oBK8mvtleI94`,
    createuser: `${version}/J2nve/oUBdgmQDDyzBbJp`,
  },
};
