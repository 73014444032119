import { BASE_URL, ENDPOINT_URL } from "../../config/urlConfig.js";
import { ApiService } from "../../config/apiServices.js";

// Add dipartment
export const AddenterpriseApi = (data) => {
  const METHOD_URL = BASE_URL + ENDPOINT_URL?.enterprise.create;
  return ApiService.post(METHOD_URL, data);
};

export const ElementsSidebarApi = (data) => {
  const METHOD_URL = BASE_URL + ENDPOINT_URL?.elements.left_side_bar;
  return ApiService.post(METHOD_URL, data);
};

export const enterpriseApi = () => {
  const METHOD_URL = BASE_URL + ENDPOINT_URL?.enterprise.getinterprise;
  return ApiService.post(METHOD_URL,{});
};

export const EditenterpriseApi = (data) => {
  const METHOD_URL = BASE_URL + ENDPOINT_URL?.enterprise.edit;
  return ApiService.post(METHOD_URL, data);
};
export const deleteEnterpriseApi = (data) => {
  const METHOD_URL = BASE_URL + ENDPOINT_URL?.enterprise.delete;
  return ApiService.post(METHOD_URL, data);
};
export const validateEnterpriseKeyApi = (data) => {
  const METHOD_URL = BASE_URL + ENDPOINT_URL?.enterprise.fetchkeysvalue;
  return ApiService.post(METHOD_URL, data);
};
export const getPreviousLicenseDetails = (data) => {
  const METHOD_URL = BASE_URL + ENDPOINT_URL?.enterprise.fetchpreviouslicensedetails;
  return ApiService.post(METHOD_URL, data);
};
export const getOrgAdminList = (data) => {
  const METHOD_URL = BASE_URL + ENDPOINT_URL?.enterprise.orgadminlist;
  return ApiService.post(METHOD_URL, data);
};
export const createAdminUser = (data) => {
  const METHOD_URL = BASE_URL + ENDPOINT_URL?.enterprise.createuser;
  return ApiService.post(METHOD_URL, data);
};


