import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Divider,
} from "@mui/material";
import { Search, Delete, Edit } from "@mui/icons-material";
// import xicon from "../../assets/svg/Xicon.svg";
// import Filterslines from "../../assets/svg/filter.svg";
import { useDispatch, useSelector } from "react-redux";
import Addicon from "../../assets/svg/Addicon.svg";
import Enterprises from "./enterprises";
import { Getentrprise, deleteEnterprise } from "../../store/enterprise/action";
import DeleteConfirmationPopup from "../common/DeleteConfirmationPopup";

// Dummy data
const getStatusStyle = (status) => ({
  color: status ? "#027A48" : "#B42318",
  backgroundColor: status ? "#ECFDF3" : "#FEF3F2",
  padding: "5px 10px",
  borderRadius: "15px",
  display: "inline-block",
});

const EnterprisesList = () => {
  const disptach = useDispatch();
  const { enterpriseslist } = useSelector((state) => state.enterprises);
  const [open, setOpen] = useState(false);
  const [editdata, setEditdata] = useState(null);
  const [Alert, setAlert] = useState(false);

  useEffect(() => {
    console.log('first item selected')
    disptach(Getentrprise({}));
  }, []);
  const onConfirm = () => {
    const payload = {
      EnterpriseID: editdata?.EnterpriseID,
    };
    disptach(deleteEnterprise(payload));
    setAlert(false);
  };

  const OnClose = () => {
    setOpen(false);
    setEditdata(null);
  };

  return (
    <>
      <Box
        sx={{
          margin: "25px",
          borderRadius: "8px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Typography variant="h6" style={{ fontWeight: "500" }}>
            Enterprises
          </Typography>
          <Typography variant="body2" color="textSecondary">
            List of all the Enterprises
          </Typography>
        </Box>

        <Button
          sx={{
            height: "40px",
          }}
          variant="contained"
          size="small"
          endIcon={<img src={Addicon} alt="Addicon" />}
          onClick={() => {
            setOpen(!open);
          }}
        >
          Add
        </Button>
      </Box>
      <Box
        sx={{
          padding: "20px",
          backgroundColor: "#fff",
          margin: "25px",
          borderRadius: "8px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
        </Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Enterprise Name</TableCell>
                <TableCell>Enterprise Description</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {enterpriseslist?.map((row) => (
                <TableRow key={row.EnterpriseID}>
                  <TableCell>{row?.EnterpriseName}</TableCell>
                  <TableCell>{row?.EnterpriseDescription}</TableCell>
                  <TableCell>
                    <Box sx={getStatusStyle(row?.IsActive)}>
                      {row?.IsActive ? "Active" : "Inactive"}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => {
                        setOpen(!open);
                        setEditdata(row);
                      }}
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        setAlert(!Alert);
                        setEditdata(row);
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 2,
          }}
        >
          <div>
            <Button
              variant="outlined"
              sx={{
                borderColor: "#D0D5DD",
                color: "#344054",
                textTransform: "none",
                marginRight: "12px",
                borderRadius: "8px",
              }}
            >
              Previous
            </Button>
            <Button
              variant="outlined"
              sx={{
                borderColor: "#D0D5DD",
                color: "#344054",
                textTransform: "none",
                borderRadius: "8px",
              }}
            >
              Next
            </Button>
          </div>
          <div>
            <Typography sx={{ color: "#344054" }}>Page 1 of 10</Typography>
          </div>
        </Box>
        <DeleteConfirmationPopup
          open={Alert}
          onClose={() => {
            setAlert(!Alert);
          }}
          onConfirm={() => {
            onConfirm();
          }}
          title={
            "Are you sure you want to delete this? This action cannot be undone."
          }
        />

        <Enterprises open={open} OnClose={OnClose} editdata={editdata} />
      </Box>
    </>
  );
};

export default EnterprisesList;
