import React, { useEffect, useState } from 'react'
import Addicon from "../../assets/svg/Addicon.svg";
import { Avatar, Box, Button, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import DeleteConfirmationPopup from '../common/DeleteConfirmationPopup';
import { Delete, Edit } from '@mui/icons-material';
import { addAdminUser, orgAdminLinks } from '../../store/enterprise/action';
import { useDispatch } from 'react-redux';
import AddUser from './AddUser';
import { createAdminUser } from '../../services/enterprise/enterprise';


const getStatusStyle = (status) => ({
    color: status ? "#027A48" : "#B42318",
    backgroundColor: status ? "#ECFDF3" : "#FEF3F2",
    padding: "5px 10px",
    borderRadius: "15px",
    display: "inline-block",
});
export default function UserManagement() {
    const [userList, setUserList] = useState([]);
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        getAdminListOrganizationWise()
    }, []);
    const getAdminListOrganizationWise = async () => {
        try {
            const resp = await dispatch(orgAdminLinks({}));
            console.log(resp)
            setUserList(resp.payload)
        } catch (error) {

        }
    }

    return (
        <>
            <AddUser 
            open={open} 
            setOpen={setOpen} 
            getAdminListOrganizationWise={getAdminListOrganizationWise} />
            <Box
                sx={{
                    margin: "25px",
                    borderRadius: "8px",
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <Box>
                    <Typography variant="h6" style={{ fontWeight: "500" }}>
                        User Mangement
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        List of all the Enterprises Admin Users
                    </Typography>
                </Box>

                <Button
                    sx={{
                        height: "40px",
                    }}
                    variant="contained"
                    size="small"
                    endIcon={<img src={Addicon} alt="Addicon" />}
                    onClick={() => {
                        setOpen(true);
                    }}
                >
                    Add
                </Button>
            </Box>
            <Box
                sx={{
                    padding: "20px",
                    backgroundColor: "#fff",
                    margin: "25px",
                    borderRadius: "8px",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                </Box>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>Organization Name</TableCell>
                                <TableCell>Admin Name</TableCell>
                                <TableCell>User ID</TableCell>
                                <TableCell>User Email</TableCell>
                                {/* <TableCell>Action</TableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {userList?.map((row) => (
                                <TableRow key={row.OrganizationStructureID}>
                                    <TableCell>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "10px",
                                            }}
                                        >
                                            <Avatar
                                                alt={row?.UserFirstName || "User"}
                                                src={
                                                    row?.UserPhoto ||
                                                    `https://i.pravatar.cc/150?u=${row.UserID}`
                                                }
                                            />
                                        </Box>
                                    </TableCell>
                                    <TableCell>{row?.OrganizationStructureName}</TableCell>
                                    <TableCell>{row?.UserFirstName} {row?.UserMiddleName} {row?.UserLastName}</TableCell>
                                    <TableCell>{row?.UserName}</TableCell>
                                    <TableCell>{row?.UserEmail}</TableCell>
                                    {/* <TableCell>
                                        <IconButton
                                            onClick={() => {
                                            }}
                                        >
                                            <Edit />
                                        </IconButton>
                                        <IconButton
                                            onClick={() => {
                                            }}
                                        >
                                            <Delete />
                                        </IconButton>
                                    </TableCell> */}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: 2,
                    }}
                >
                    <div>
                        <Button
                            variant="outlined"
                            sx={{
                                borderColor: "#D0D5DD",
                                color: "#344054",
                                textTransform: "none",
                                marginRight: "12px",
                                borderRadius: "8px",
                            }}
                        >
                            Previous
                        </Button>
                        <Button
                            variant="outlined"
                            sx={{
                                borderColor: "#D0D5DD",
                                color: "#344054",
                                textTransform: "none",
                                borderRadius: "8px",
                            }}
                        >
                            Next
                        </Button>
                    </div>
                    <div>
                        <Typography sx={{ color: "#344054" }}>Page 1 of 10</Typography>
                    </div>
                </Box>
                <DeleteConfirmationPopup
                    open={''}
                    onClose={() => {
                    }}
                    onConfirm={() => {
                    }}
                    title={
                        "Are you sure you want to delete this? This action cannot be undone."
                    }
                />
            </Box>
        </>
    )
}
