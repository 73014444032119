import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  Typography,
  InputAdornment,
  CircularProgress,
  IconButton,
} from "@mui/material";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from "react-simple-captcha";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Login.css";
import UseLoginSubmit from "../../hooks/auth";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import img1 from "../../assets/image/LoginPage/img1.jpg";
import img2 from "../../assets/image/LoginPage/img2.jpg";
import img3 from "../../assets/image/LoginPage/img3.jpg";
import user from "../../assets/svg/LoginPage/User.svg";
import lock from "../../assets/svg/LoginPage/Lock.svg";
import loginButton from "../../assets/svg/LoginPage/arrow-right-circle.svg";

const Login = () => {
  const { register, handleSubmit, LoginSubmit } = UseLoginSubmit();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [failedAttempts, setFailedAttempts] = useState(
    parseInt(localStorage.getItem("failedAttempts")) || 0
  );
  const [showCaptcha, setShowCaptcha] = useState(
    localStorage.getItem("showCaptcha") === "true"
  );
  const [captchaInput, setCaptchaInput] = useState("");
  const [formErrors, setFormErrors] = useState({
    username: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((prev) => !prev);

  useEffect(() => {
    if (failedAttempts >= 3) {
      setShowCaptcha(true);
      // Delay loading captcha to ensure the component has fully rendered
      setTimeout(() => {
        loadCaptchaEnginge(6); // Initialize captcha with 6 characters
      }, 100);
    }
  }, [failedAttempts]);

  useEffect(() => {
    // Store captcha visibility and failed attempts in localStorage
    localStorage.setItem("failedAttempts", failedAttempts);
    localStorage.setItem("showCaptcha", showCaptcha);
  }, [failedAttempts, showCaptcha]);

  const handleLoginSubmit = async (data) => {
    setErrorMessage("");
    setFormErrors({ username: "", password: "" });
    setLoading(true);

    const payload = {
      username: data.username,
      password: data.password,
    };

    // Validate Captcha
    if (showCaptcha && !validateCaptcha(captchaInput)) {
      setErrorMessage("Incorrect captcha code.");
      setFailedAttempts((prev) => prev + 1);
      setCaptchaInput(""); // Clear captcha input
      loadCaptchaEnginge(6); // Reload a new captcha
      setLoading(false);
      return;
    }

    try {
      const success = await LoginSubmit(payload, setErrorMessage);
      if (success) {
        localStorage.removeItem("failedAttempts");
        localStorage.removeItem("showCaptcha");
        navigate("/dashboard");
      } else {
        setFailedAttempts((prev) => prev + 1);
        if (failedAttempts + 1 >= 3) {
          setShowCaptcha(true);
        }
      }
    } catch (error) {
      console.error("An error occurred during login.", error);
    } finally {
      setLoading(false);
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    appendDots: (dots) => (
      <div
        style={{
          position: "absolute",
          bottom: "10px",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        style={{
          width: "25px",
          marginRight: "20px",
          height: "2px",
          background:
            i === settings.currentSlide ? "white" : "rgba(255, 255, 255, 0.2)",
        }}
      ></div>
    ),
  };

  const slides = [
    {
      image: img1,
      heading: "Digitize SOPs",
      paragraph:
        "Transform your Standard Operating Procedures into digital, easily accessible formats. Visualize and manage your processes with graphical displays, ensuring consistency and compliance.",
    },
    {
      image: img2,
      heading: "Training and Simulation",
      paragraph:
        "Simulate real-world processes with hands-on practice modes and tests to ensure your team is ready for success.",
    },
    {
      image: img3,
      heading: "State of Art Impact Analysis",
      paragraph:
        "Gain insights into the potential impact of changes across all elements and roles. Make informed decisions by analyzing the ripple effects before implementing any modifications.",
    },
  ];

  return (
    <div className="root">
      <div className="navbar">
        <Typography
          variant="p"
          className="login-heading"
          sx={{
            fontSize: "1.5rem",
            lineHeight: "3rem",
            padding: "0px 10px",
            letterSpacing: "0.2rem",
          }}
        >
          PLUTO
        </Typography>
      </div>
      <div className="content">
        <div className="left-pane">
          <Slider className="slider" {...settings}>
            {slides?.map((slide, index) => (
              <div key={index} className="slider-item">
                <img src={slide.image} alt={`slide-${index}`} />
                <div className="slider-content">
                  <Typography
                    variant="h4"
                    component="h1"
                    className="login-heading"
                  >
                    {slide.heading}
                  </Typography>
                  <Typography
                    variant="body1"
                    component="p"
                    className="login-para"
                  >
                    {slide.paragraph}
                  </Typography>
                </div>
              </div>
            ))}
          </Slider>
        </div>
        <div className="right-pane">
          <form
            onSubmit={handleSubmit(handleLoginSubmit)}
            className="login-form"
          >
            <Typography
              variant="h4"
              component="p"
              gutterBottom
              className="rightside-heading"
            >
              PLUTO
            </Typography>

            <TextField
              className="text-field"
              variant="outlined"
              placeholder="Email"
              {...register("username")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={user} alt="" />
                  </InputAdornment>
                ),
                style: {
                  backgroundColor: "white",
                  borderRadius: "var(--radiuslg)",
                },
              }}
            />
            {formErrors.username && (
              <Typography
                variant="body2"
                color="error"
                sx={{ mb: 1, textAlign: "left" }}
              >
                {formErrors.username}
              </Typography>
            )}
            <TextField
              className="text-field"
              variant="outlined"
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              {...register("password")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={lock} alt="" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClickShowPassword}>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
                style: {
                  backgroundColor: "white",
                  borderRadius: "var(--radiuslg)",
                },
              }}
            />
            {formErrors.password && (
              <Typography
                variant="body2"
                color="error"
                sx={{ mb: 1, textAlign: "left" }}
              >
                {formErrors.password}
              </Typography>
            )}
            {errorMessage && (
              <Typography
                variant="body2"
                color="error"
                sx={{ mb: 1, textAlign: "left" }}
              >
                {errorMessage}
              </Typography>
            )}

            {showCaptcha && (
              <>
                <LoadCanvasTemplate />
                <TextField
                  className="text-field"
                  variant="outlined"
                  placeholder="Enter Captcha Code"
                  value={captchaInput}
                  onChange={(e) => setCaptchaInput(e.target.value)}
                  InputProps={{
                    style: {
                      backgroundColor: "white",
                      borderRadius: "var(--radiuslg)",
                    },
                  }}
                />
              </>
            )}
            <Button
              className="login-button"
              variant="contained"
              color="primary"
              endIcon={
                !loading && <img src={loginButton} alt="Login Button Icon" />
              }
              type="submit"
              disabled={loading}
            >
              {loading ? (
                <CircularProgress
                  size={28}
                  color="inherit"
                  style={{ color: "#fff" }}
                />
              ) : (
                "Login"
              )}
            </Button>
          </form>
          <Typography variant="body2" component="p" className="powered-by">
            Powered By PLUTO
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default Login;
