import { Box, Button, CircularProgress, Divider, MenuItem, Modal, OutlinedInput, Select, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { addAdminUser, Getentrprise } from '../../store/enterprise/action';
import usersIcon from "../../assets/svg/AddUser/user.svg";

export default function AddUser({ open, setOpen, getAdminListOrganizationWise }) {
    const { enterpriseslist } = useSelector((state) => state.enterprises);
    const [errors, setErrors] = useState({});
    const [formState, setFormState] = useState({
        UserName: "",
        UserEmail: "",
        Password: "",
        Name: "",
        OrganizationStructureID: "",
        UserPhoneNumber: ""
    });
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch()

    useEffect(() => {
        const payload = { ParentID: null };
        dispatch(Getentrprise(payload));
    }, [dispatch]);

    const onChange = (e) => {
        setFormState({ ...formState, [e.target.name]: e.target.value });
    }

    const handleClose = () => {
        setOpen(false);
    }
    const validate = async () => {
        let isError = false,newErrors = []
        for await (const [key, value] of Object.entries(formState)) {
            if (value === "") {
                newErrors.push({[key]: `${key} is required` });
                isError = true;
            }
        }
        setErrors(newErrors);
        return isError;
    }
    const handleClick = async () => {
        const validateStatus = await validate();
        if (validateStatus) return;
        await dispatch(addAdminUser(formState));
        getAdminListOrganizationWise()
        setOpen(false);
        setFormState({
            UserName: "",
            UserEmail: "",
            Password: "",
            Name: "",
            OrganizationStructureID: "",
            UserPhoneNumber: ""
        });
    }
    return (
        <div>
            <Modal open={open} onClose={handleClose} sx={{ maxHeight: '100vh', overflowY: 'scroll' }}>
                <Box
                    sx={{
                        width: 600,
                        bgcolor: "background.paper",
                        borderRadius: 1,
                        padding: 4,
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        boxShadow: 24
                    }}
                >
                    <Box display="flex" flexDirection="column" gap="24px">
                        <Box display="flex" alignItems="center" gap="16px">
                            <img src={usersIcon} alt="logo" />
                            <Box>
                                <Typography variant="h6">
                                    Add Andmin User
                                </Typography>
                                <Typography variant="body2" style={{ color: "#64748B" }}>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                </Typography>
                            </Box>
                        </Box>
                        <Divider />
                        <div>
                            <Typography className="input-label" variant="body2">
                                Client Name
                            </Typography>
                            <Select
                                onChange={onChange}
                                name='OrganizationStructureID'
                                input={<OutlinedInput />}
                                fullWidth
                                className="element-select"
                                displayEmpty
                                value={formState.OrganizationStructureID || ""}
                                error={!!errors?.OrganizationStructureID}
                                helperText={errors?.OrganizationStructureID}
                            >
                                <MenuItem disabled
                                    key={'1'}
                                    value={""}
                                >
                                    <Typography variant="body1">
                                        Select Organization
                                    </Typography>
                                </MenuItem>
                                {enterpriseslist?.length > 0 &&
                                    enterpriseslist.map((item) => (
                                        <MenuItem
                                            key={item.EnterpriseID}
                                            value={item.EnterpriseID}
                                        >
                                            <Typography variant="body1">
                                                {item?.EnterpriseName}
                                            </Typography>
                                        </MenuItem>
                                    ))}
                            </Select>
                        </div>
                        <Box>
                            <Typography>UserName</Typography>
                            <TextField
                                type="text"
                                placeholder="Enter Unique UserName..."
                                name="UserName"
                                onChange={onChange}
                                value={formState.UserName}
                                error={!!errors?.UserName}
                                helperText={errors?.UserName}
                                fullWidth
                                variant="outlined"
                                size="small"
                            />
                        </Box>
                        <Box>
                            <Typography>Password</Typography>
                            <TextField
                                type="password"
                                name="Password"
                                placeholder="Enter Password..."
                                variant="outlined"
                                fullWidth
                                onChange={onChange}
                                value={formState.Password}
                                error={!!errors?.Password}
                                helperText={errors?.Password}
                                size="small"
                            />
                        </Box>
                        <Box>
                            <Typography>Name</Typography>
                            <TextField
                                type="text"
                                placeholder="Enter Name..."
                                name="Name"
                                onChange={onChange}
                                value={formState.Name}
                                error={!!errors?.Name}
                                helperText={errors?.Name}
                                fullWidth
                                variant="outlined"
                                size="small"
                            />
                        </Box>
                        <Box>
                            <Typography>Email</Typography>
                            <TextField
                                type="email"
                                placeholder="Enter email..."
                                name="UserEmail"
                                onChange={onChange}
                                value={formState.UserEmail}
                                error={!!errors?.UserEmail}
                                helperText={errors?.UserEmail}
                                fullWidth
                                variant="outlined"
                                size="small"
                            />
                        </Box>
                        <Box>
                            <Typography>Phone Number</Typography>
                            <TextField
                                type="text"
                                placeholder="Enter Phone Number..."
                                name="UserPhoneNumber"
                                onChange={onChange}
                                value={formState.UserPhoneNumber}
                                error={!!errors?.UserPhoneNumber}
                                helperText={errors?.UserPhoneNumber}
                                fullWidth
                                variant="outlined"
                                size="small"
                            />
                        </Box>
                        <Divider />
                        <Box mt={3} display="flex" justifyContent="space-between">
                            <Button
                                variant="outlined"
                                onClick={handleClose}
                                style={{ marginRight: 8 }}
                                size="small"
                                fullWidth
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                onClick={handleClick}
                                size="small"
                                disabled={loading}
                                fullWidth
                            >
                                {loading ? <CircularProgress size={24} /> : "Save"}
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </div>
    )
}
